import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-542beab3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-details" }
const _hoisted_2 = { class: "order-summary" }
const _hoisted_3 = { class: "order-summary-thumbnail" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "wrapper" }
const _hoisted_6 = { class: "order-summary-id" }
const _hoisted_7 = {
  key: 0,
  class: "order-summary-manufacturer"
}
const _hoisted_8 = { class: "order-summary-part-numbers-revisions" }
const _hoisted_9 = {
  key: 1,
  class: "order-summary-tags"
}
const _hoisted_10 = { class: "side-wrapper" }
const _hoisted_11 = {
  key: 0,
  class: "order-summary-first-article status-icon"
}
const _hoisted_12 = { class: "order-details-tables" }
const _hoisted_13 = { class: "order-details-content-flex-wrapper" }
const _hoisted_14 = { class: "order-details-content-wrapper" }
const _hoisted_15 = { class: "order-details-side-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.showUpdateDeliveryDateModal)
      ? (_openBlock(), _createBlock($setup["UpdateDeliveryDateModal"], {
          key: 0,
          show: $setup.showUpdateDeliveryDateModal,
          "onUpdate:show": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.showUpdateDeliveryDateModal) = $event)),
            $setup.onUpdateDeliveryDateModalClose
          ],
          id: $setup.order.id,
          deliveryDate: $setup.deliveryDate,
          targetDate: $setup.dueDate,
          orderType: $setup.OrderTypes.Manufacturers,
          onDateUpdated: $setup.onDeliveryDateUpdated,
          onClose: $setup.onUpdateDeliveryDateModalClose
        }, null, 8, ["show", "id", "deliveryDate", "targetDate", "orderType"]))
      : _createCommentVNode("", true),
    ($setup.showUpdateProductionStageModal)
      ? (_openBlock(), _createBlock($setup["UpdateProductionStageModal"], {
          key: 1,
          show: $setup.showUpdateProductionStageModal,
          "onUpdate:show": [
            _cache[1] || (_cache[1] = ($event: any) => (($setup.showUpdateProductionStageModal) = $event)),
            $setup.onUpdateProductionStageModalClose
          ],
          orderId: $setup.updateProductionStageOrderId,
          partId: $setup.updateProductionStagePartId,
          stageId: $setup.updateProductionStageStageId,
          status: $setup.updateProductionStageStatus,
          costingId: $setup.updateProductionStageCostingId,
          onProductionStageUpdated: $setup.onProductionStageUpdated,
          onClose: $setup.onUpdateProductionStageModalClose
        }, null, 8, ["show", "orderId", "partId", "stageId", "status", "costingId", "onClose", "onUpdate:show"]))
      : _createCommentVNode("", true),
    ($setup.showEscalateModal)
      ? (_openBlock(), _createBlock($setup["EscalateModal"], {
          key: 2,
          show: $setup.showEscalateModal,
          "onUpdate:show": [
            _cache[2] || (_cache[2] = ($event: any) => (($setup.showEscalateModal) = $event)),
            $setup.onEscalateModalClose
          ],
          id: $setup.order.id,
          orderType: $setup.OrderTypes.Manufacturers,
          flaggedLevel: $setup.order.flaggedLevel,
          onClose: $setup.onEscalateModalClose,
          onEscalationUpdated: $setup.onEscalationUpdated
        }, null, 8, ["show", "id", "orderType", "flaggedLevel"]))
      : _createCommentVNode("", true),
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["NSpin"], {
          key: 3,
          size: "large"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["Loader"], { loading: $setup.previewLoading }, null, 8, ["loading"]),
              (!$setup.previewLoading)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: $setup.order.thumbnailUrl          || require('@/assets/placeholders/order-details/order-thumbnail-placeholder.png'),
                    alt: "order-thumbnail",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.goToOrderPartInfoPage('manufacturer-order', $setup.orderPartId, $setup.order.part.costingId))),
                    class: _normalizeClass({clickable: $setup.showPartInfo($setup.orderPartId)})
                  }, null, 10, _hoisted_4))
                : _createCommentVNode("", true),
              ($setup.showPartInfo($setup.orderPartId))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "order-summary-thumbnail-part-info-label",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.goToOrderPartInfoPage('manufacturer-order', $setup.orderPartId, $setup.order.part.costingId)), ["stop"]))
                  }, "Part Info"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(`${$setup.order.mfrOrderId.number}-${$setup.order.lineNumber}`), 1),
              ($setup.order.mfrOrderId.mfr && $setup.order.mfrOrderId.mfr.fullName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(`${$setup.formatManufacturerNumber($setup.order.mfrOrderId.mfr.number)} - ${$setup.order.mfrOrderId.mfr.fullName}`), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, _toDisplayString(`${$setup.order.partNumber}-${$setup.order.partRev}`), 1),
              ($setup.order.part.tags.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.order.part.tags, (tag) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "order-summary-tags-tag",
                        key: tag
                      }, _toDisplayString(tag), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              ($setup.order.isFA)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                : _createCommentVNode("", true),
              ($setup.order.flaggedLevel)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["order-summary-flagged status-icon", $setup.getFlaggedLevelClass($setup.order.flaggedLevel)])
                  }, null, 2))
                : _createCommentVNode("", true),
              _createVNode($setup["NDropdown"], {
                class: "main-dropdown",
                show: $setup.showActionMenu,
                "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => (($setup.showActionMenu) = $event)),
                options: $setup.actionMenuOptions,
                placement: "bottom-end",
                trigger: "click",
                onSelect: $setup.handleActionMenuOptionSelect
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NButton"], {
                    class: "extra-small-button n-button-actions n-button-with-arrow",
                    round: "",
                    ghost: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Actions"),
                      _createVNode($setup["ChevronDown"], { color: "currentColor" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["show", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode($setup["OrderLineInfo"], {
              data: $setup.orderLineInfoData,
              columns: $setup.orderLineInfoColumns,
              openOrdersLength: $setup.openOrders.length,
              showOpenOrders: $setup.showOpenOrders,
              "onUpdate:showOpenOrders": _cache[6] || (_cache[6] = ($event: any) => (($setup.showOpenOrders) = $event))
            }, null, 8, ["data", "openOrdersLength", "showOpenOrders"]),
            ($setup.showOpenOrders)
              ? (_openBlock(), _createBlock($setup["OpenOrders"], {
                  key: 0,
                  data: $setup.openOrders,
                  columns: $setup.orderLineInfoColumns,
                  showOpenOrders: $setup.showOpenOrders,
                  "onUpdate:showOpenOrders": _cache[7] || (_cache[7] = ($event: any) => (($setup.showOpenOrders) = $event))
                }, null, 8, ["data", "showOpenOrders"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode($setup["AdminMfrOrderDetailsDeliveryDates"], { order: $setup.order }, null, 8, ["order"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode($setup["Comments"], {
                commentViewOptions: $setup.commentViewOptions,
                id: `${$setup.route.params.id}`
              }, null, 8, ["commentViewOptions", "id"])
            ])
          ])
        ], 64))
  ]))
}